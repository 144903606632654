// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-apis-are-not-enough-js": () => import("./../../../src/pages/insights/apis-are-not-enough.js" /* webpackChunkName: "component---src-pages-insights-apis-are-not-enough-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-insights-riding-the-api-wave-js": () => import("./../../../src/pages/insights/riding-the-api-wave.js" /* webpackChunkName: "component---src-pages-insights-riding-the-api-wave-js" */),
  "component---src-pages-work-building-the-future-of-payments-js": () => import("./../../../src/pages/work/building-the-future-of-payments.js" /* webpackChunkName: "component---src-pages-work-building-the-future-of-payments-js" */),
  "component---src-pages-work-fiserv-dashboard-js": () => import("./../../../src/pages/work/fiserv-dashboard.js" /* webpackChunkName: "component---src-pages-work-fiserv-dashboard-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-netapp-js": () => import("./../../../src/pages/work/netapp.js" /* webpackChunkName: "component---src-pages-work-netapp-js" */),
  "component---src-pages-work-twitch-js": () => import("./../../../src/pages/work/twitch.js" /* webpackChunkName: "component---src-pages-work-twitch-js" */),
  "component---src-pages-work-twitter-data-js": () => import("./../../../src/pages/work/twitter-data.js" /* webpackChunkName: "component---src-pages-work-twitter-data-js" */)
}

